<template>
  <div class="c_hero_banner" @mouseover="pauseAutoplay()" @mouseleave="setAutoplay()">
    <Overlay class="c_hero_banner__overlay" ref="videoBannerOverlay" theme="full" :show-close-button="true">
      <div class="c_box--pos--relative c_hero_banner__overlay__video">
        <MediaVideo loading="eager" :source="videoSource" :video-id="videoId"></MediaVideo>
      </div>
    </Overlay>
    <!-- [START] SLIDE -->
    <section
      class="c_hero_banner__slide"
      v-for="(slide, key) in slideData"
      :key="key"
      :class="[{ active: key === currentSlideId }, desktopBannerAspectSizingClass, mobileBannerAspectSizingClass]"
    >
      <div
        class="sizing-box"
        :class="[desktopBannerSizingClass, mobileBannerSizingClass, stackTextImageMobile]"
        :style="
          '--mobile-banner-height:' +
          bannerSize.mobileBannerHeight +
          '; --desktop-banner-height:' +
          bannerSize.desktopBannerHeight +
          ';'
        "
      >
        <div class="c_hero_banner__background" :class="[slideThemeClass, stackTextImageMobile]">
          <!--[START] Desktop Variant BG (default)-->
          <div class="c_hero_banner__background__overlay dark" :style="'--opacity:' + slide.overlayOpacity"></div>
          <div
            v-if="slide.desktopBgVideoUrl && slide.desktopBgImageUrl"
            class="pause-resume"
            @click="toggleVideoState(key)"
          >
            <IconSVG height="20" width="30" :handle="pauseResumeIcon[key]" aria-hidden="true" />
          </div>
          <div
            v-if="slide.desktopBgImageUrl"
            class="c_hero_banner__background__visual desktop"
            :style="'background-image: url(' + slide.desktopBgImageUrl + ')'"
          >
            <video
              v-if="slide.desktopBgVideoUrl && slide.desktopBgImageUrl"
              :id="'slideVideo' + key"
              :ref="'slideVideo' + key"
              class="c_hero_banner__background__visual__video"
              autoplay
              muted
              loop
              :poster="slide.desktopBgImageUrl"
            >
              <source :src="slide.desktopBgVideoUrl" type="video/mp4" />
            </video>
          </div>
          <!--[START] Mobile Variant BG-->
          <MediaImage
            background
            v-if="slide.mobileBgImageSrcset"
            :sources="[slide.mobileBgImageSrcset]"
            class="c_hero_banner__background__visual mobile"
          />
        </div>
        <div class="c_hero_banner__content" :class="[stackTextImageMobile]">
          <section class="content_wrapper">
            <div
              class="content-container-box"
              :style="
                '--desktop-text-order:' +
                slide.content.desktopTextOrder +
                '; --mobile-text-order:' +
                slide.content.mobileTextOrder +
                '; --desktop-text-box-width:' +
                slide.content.desktopTextBoxWidth +
                '; --desktop-text-align:' +
                slide.content.desktopTextAlign +
                '; --mobile-text-align:' +
                slide.content.mobileTextAlign +
                ';'
              "
            >
              <div
                class="content-box"
                :class="[
                  slideContentBoxThemeClass,
                  stackTextImageMobile ? '' : textIndicatorThemeClass,
                  textShadowThemeClass,
                ]"
                :style="
                  '--shadow-offset:' +
                  slide.content.textShadowOffset +
                  'px;' +
                  '--shadow-blur:' +
                  slide.content.textShadowBlurStrength +
                  'px;'
                "
              >
                <p v-if="slide.content.eyebrow" v-html="slide.content.eyebrow" class="c_lp_text__eyebrow"></p>
                <h1
                  v-if="slide.content.header"
                  v-html="slide.content.header"
                  class="c_lp_text__subheader c_header--10 c_text--graphik_bold"
                ></h1>
                <div v-if="slide.content.description" class="c_lp_text__text c_text c_text--graphik_regular">
                  <template v-if="stackTextImageMobile === 'stack-items'">
                    <div class="c_hero_banner__content_stacked_text" v-html="slide.content.description"></div>
                  </template>
                  <div v-else v-html="slide.content.description"></div>
                </div>
                <div
                  class="cta_container"
                  :style="
                    '--desktop-justify-align:' +
                    slide.content.desktopFlexButtonAlign +
                    '; --mobile-justify-align:' +
                    slide.content.mobileFlexButtonAlign +
                    ';'
                  "
                >
                  <span v-if="slide.content.primaryBtn.type == 'default'">
                    <a
                      v-if="
                        slide.content.primaryBtn.url &&
                        slide.content.primaryBtn.label &&
                        !slide.content.secondaryBtn.label
                      "
                      :class="['c_button', 'primary-btn', slide.content.primaryBtn.themeClass]"
                      :href="slide.content.primaryBtn.url"
                      :aria-label="slide.content.primaryBtn.ariaLabel"
                      :target="slide.content.primaryBtn.target"
                      style="margin-right: 0 !important"
                      >{{ slide.content.primaryBtn.label }}</a
                    >
                    <a
                      v-else-if="slide.content.primaryBtn.url && slide.content.primaryBtn.label"
                      :class="['c_button', 'primary-btn', slide.content.primaryBtn.themeClass]"
                      :href="slide.content.primaryBtn.url"
                      :aria-label="slide.content.primaryBtn.ariaLabel"
                      :target="slide.content.primaryBtn.target"
                      >{{ slide.content.primaryBtn.label }}</a
                    >
                  </span>
                  <span
                    v-if="
                      slide.content.primaryBtn.type.match(/(youtube|youku).*$/) && !slide.content.secondaryBtn.label
                    "
                  >
                    <Button
                      @click="showPopupVideo(slide.content.primaryBtn.videoId, slide.content.primaryBtn.type)"
                      :class="['c_button', 'primary-btn', slide.content.primaryBtn.themeClass]"
                      unstyle
                      reset
                      :aria-label="$t('Open media in popup overlay')"
                      style="margin-right: 0 !important"
                    >
                      {{ slide.content.primaryBtn.label }}
                    </Button>
                  </span>
                  <span v-else-if="slide.content.primaryBtn.type.match(/(youtube|youku).*$/)">
                    <Button
                      @click="showPopupVideo(slide.content.primaryBtn.videoId, slide.content.primaryBtn.type)"
                      :class="['c_button', 'primary-btn', slide.content.primaryBtn.themeClass]"
                      unstyle
                      reset
                      :aria-label="$t('Open media in popup overlay')"
                    >
                      {{ slide.content.primaryBtn.label }}
                    </Button>
                  </span>
                  <span v-if="slide.content.secondaryBtn.type == 'default'">
                    <a
                      v-if="slide.content.secondaryBtn.url && slide.content.secondaryBtn.label"
                      :class="['c_button', 'secondary-btn', slide.content.secondaryBtn.themeClass]"
                      :href="slide.content.secondaryBtn.url"
                      :aria-label="slide.content.secondaryBtn.ariaLabel"
                      :target="slide.content.secondaryBtn.target"
                      style="margin-right: 0 !important"
                      >{{ slide.content.secondaryBtn.label }}</a
                    >
                  </span>
                  <span v-if="slide.content.secondaryBtn.type.match(/(youtube|youku).*$/)">
                    <Button
                      @click="showPopupVideo(slide.content.secondaryBtn.videoId, slide.content.secondaryBtn.type)"
                      :class="['c_button', 'secondary-btn', slide.content.secondaryBtn.themeClass]"
                      unstyle
                      reset
                      :aria-label="$t('Open media in popup overlay')"
                      style="margin-right: 0 !important"
                    >
                      {{ slide.content.secondaryBtn.label }}
                    </Button>
                  </span>
                </div>
              </div>
            </div>
            <div
              class="image-box"
              :style="
                '--desktop-image-order:' +
                slide.content.desktopImageOrder +
                '; --mobile-image-order:' +
                slide.content.mobileImageOrder +
                '; --desktop-image-display-class:' +
                slide.content.imageDisplayClassDesktop +
                '; --mobile-image-display-class:' +
                slide.content.imageDisplayClassMobile +
                '; --desktop-image-height:' +
                slide.content.imageHeight +
                ';'
              "
            >
              <div
                v-if="slide.content.imageUrl"
                class="image-box-image"
                :style="'background-image: url(' + slide.content.imageUrl + ')'"
              ></div>
            </div>
          </section>
        </div>
      </div>
    </section>
    <!-- [END] SLIDE -->
    <!-- ========================================================================================= -->
    <!--[START] Hero Banner Indicator -->
    <div v-if="slideData.length > 1" class="c_hero_banner__indicator container">
      <!-- [START] Indicator (icon_indicator optional)-->
      <div class="c_hero_banner__indicator__menu__container" :class="[indicatorClass]">
        <div class="c_wrapper">
          <nav class="c_hero_banner__indicator__menu" :class="[textIndicatorThemeClass]">
            <button
              unstyle
              reset
              class="indicator_btn c_button--reset"
              v-for="(slide, key) in slideData"
              :key="key"
              :class="[{ active: key === currentSlideId }]"
              @click="goToSlide(key)"
            >
              <img
                class="c_global_header__quick_links__icon indicator_icon"
                v-if="slide.icon"
                :src="slide.icon"
                :alt="slide.name + ' Icon'"
              />
              <div :class="['indicator', indicatorAnimatedClass]" :style="'--indicator-timer:' + duration + 'ms'"></div>
              <label v-if="slide.name" class="indicator_label c_text--graphik_semibold">{{ slide.name }}</label>
            </button>
          </nav>
        </div>
      </div>
      <!-- [END] Icon Indicator -->
    </div>
    <!--[END] Hero Banner Indicator -->
    <!--[START] Arrow Navigation -->
    <div v-if="slideData.length > 1" class="c_hero_banner__navigation" :class="[textIndicatorThemeClass]">
      <nav class="arrow-nav">
        <button unstyle reset class="arrow-nav-btn left-btn c_button--reset" @click="goToPreviousSlide()">
          <div class="icon_bg"></div>
          <IconSVG
            class="arrow-icon"
            handle="arrow_left"
            :stroke-color="indicatorIconColor"
            :stroke-color-var="indicatorIconColor"
            width="20"
            height="20"
          />
        </button>
        <button unstyle reset class="arrow-nav-btn right-btn c_button--reset" @click="goToNextSlide()">
          <div class="icon_bg"></div>
          <IconSVG
            class="arrow-icon"
            handle="arrow_right"
            :stroke-color="indicatorIconColor"
            :stroke-color-var="indicatorIconColor"
            width="20"
            height="20"
          />
        </button>
      </nav>
    </div>
    <!--[END] Arrow Navigtion-->
    <!-- ========================================================================================= -->
  </div>
</template>

<script>
import IconSVG from 'Components/icon_svg/IconSVG.vue';
import Overlay from 'Components/overlay/Overlay.vue';
import MediaVideo from 'Components/video/MediaVideo.vue';
import MediaImage from 'Components/image/MediaImage.vue';

export default {
  data() {
    return {
      indicatorClass: '',
      indicatorAnimatedClass: '',
      currentSlideId: 0,
      slideThemeClass: 'white',
      textIndicatorThemeClass: 'dark',
      textShadowThemeClass: '',
      indicatorIconColor: 'black',
      slideContentBoxThemeClass: '',
      slideData: [],
      desktopBannerSizingClass: 'desktop-set-height',
      mobileBannerSizingClass: 'mobile-set-height',
      stackTextImageMobile: null,
      desktopBannerAspectSizingClass: '',
      mobileBannerAspectSizingClass: '',
      slideTimer: null,
      videoId: null,
      videoSource: null,
      isVideoPlaying: {},
    };
  },
  components: {
    IconSVG,
    Overlay,
    MediaVideo,
    MediaImage,
  },
  props: {
    bannerSize: {
      type: Object,
      default() {
        return {
          desktopBannerHeight: '80vh',
          desktopBannerAspectRatio: 'default',
          mobileBannerHeight: '80vh',
          mobileBannerAspectRatio: 'default',
        };
      },
    },
    indicatorType: String,
    autoplay: Boolean,
    duration: Number,
    slides: Array,
  },
  methods: {
    init() {
      // Add Initial Code Here
      this.setBannerSize();
      this.setSlides();
      this.setIndicator();
      this.setTheme();
      this.setAutoplay();
    },
    showPopupVideo(videoId, videoSource) {
      this.videoId = videoId;
      this.videoSource = videoSource;
      this.$nextTick(() => {
        this.$refs.videoBannerOverlay.show();
      });
    },
    setIndicator() {
      // Set's the type of indicator displayed at the bottom of the page.

      // Set Indicator Type
      switch (this.indicatorType) {
        case 'iconIndicator':
          this.indicatorClass = 'icon_indicator';
          break;
        case 'circleIndicator':
          this.indicatorClass = 'circle_indicator';
          break;
        default:
        // Do Nothing Can be expanded
      }
    },
    getCtaButtonClass(btnTYpe) {
      let btnClass = 'defaultTheme';
      switch (btnTYpe) {
        case 'slideTheme':
          btnClass = 'slideTheme';
          break;
        default:
      }
      return btnClass;
    },
    setBannerSize() {
      // Adjusts the banner size here for different aspect ratios.
      if (this.bannerSize.desktopBannerAspectRatio !== 'default') {
        // alert(this.bannerSize.desktopBannerAspectRatio);
        const desktopRatio = this.bannerSize.desktopBannerAspectRatio.split(',');
        const desktopCssClass =
          'desktop_aspect_' + desktopRatio[0].replace('.', '_') + 'x' + desktopRatio[1].replace('.', '_');
        this.desktopBannerAspectSizingClass = desktopCssClass;
        this.desktopBannerSizingClass = '';
      }
      if (this.bannerSize.mobileBannerAspectRatio !== 'default') {
        const mobileRatio = this.bannerSize.mobileBannerAspectRatio.split(',');
        const mobileCssClass =
          'mobile_aspect_' + mobileRatio[0].replace('.', '_') + 'x' + mobileRatio[1].replace('.', '_');
        this.mobileBannerAspectSizingClass = mobileCssClass;
        this.mobileBannerSizingClass = '';
      }
    },
    setSlides() {
      // Sets and Adjusts information relating to slides
      const slideArray = [];
      for (const i in this.slides) {
        const slide = {};
        slide.id = i;
        slide.name = this.slides[i].slideName;
        slide.icon = null;
        if (this.slides[i].slideIcon[0]) {
          slide.icon = this.slides[i].slideIcon[0].url;
        }
        const content = {};
        // Content Related
        content.eyebrow = this.slides[i].contentEyebrow;
        content.header = this.slides[i].contentHeader;
        content.description = this.slides[i].contentDescription;
        content.boxEnabled = this.slides[i].contentBackgroundBoxEnabled;
        content.textShadowsEnabled = this.slides[i].enableTextShadows;
        content.textShadowOffset = this.slides[i].textShadowOffset;
        content.textShadowBlurStrength = this.slides[i].textShadowBlurStrength;
        if (this.slides[i].contentImage[0]) {
          content.imageUrl = this.slides[i].contentImage[0].lpImageTextUncropped.src;

          if (this.slides[i].contentImageHeightDesktop) {
            content.imageHeight = this.slides[i].contentImageHeightDesktop;
          } else {
            content.imageHeight = '100%';
          }
        } else {
          content.imageUrl = null;
          content.imageHeight = '100%';
        }
        // Call to Action Buttons
        const primaryBtn = {};
        primaryBtn.type = 'default';
        const ctaBtnToUrl = new URL(this.slides[i].primaryCtaBtn, 'https://kodak.com');
        if (ctaBtnToUrl) {
          if (ctaBtnToUrl?.hostname.includes('youtube')) {
            const btnStringToArray = ctaBtnToUrl?.search.split('=');
            primaryBtn.type = 'youtube';
            primaryBtn.videoId = btnStringToArray[1];
          }
          if (ctaBtnToUrl?.hostname.includes('youku')) {
            const btnStringToArray = ctaBtnToUrl?.pathname.split('_');
            primaryBtn.type = 'youku';
            primaryBtn.videoId = btnStringToArray[2];
          }
        }
        primaryBtn.url = this.slides[i].primaryCtaBtn;
        primaryBtn.label = this.slides[i].primaryCtaBtnLabel;
        primaryBtn.themeClass = this.getCtaButtonClass(this.slides[i].primaryCtaBtnTheme);
        primaryBtn.ariaLabel = this.slides[i].primaryCtaBtnAriaLabel;
        primaryBtn.target = '_self';
        if (this.slides[i].primaryCtaBtnOpenInANewWindow) {
          primaryBtn.target = '_blank';
        }
        content.primaryBtn = primaryBtn;

        const secondaryBtn = {};
        secondaryBtn.type = 'default';
        const secondBtnToUrl = new URL(this.slides[i].secondaryCtaBtn, 'https://kodak.com');
        if (secondBtnToUrl) {
          if (secondBtnToUrl?.hostname.includes('youtube')) {
            const btnStringToArray = secondBtnToUrl?.search.split('=');
            secondaryBtn.type = 'youtube';
            secondaryBtn.videoId = btnStringToArray[1];
          }
          if (secondBtnToUrl?.hostname.includes('youku')) {
            const btnStringToArray = secondBtnToUrl?.pathname.split('_');
            secondaryBtn.type = 'youku';
            secondaryBtn.videoId = btnStringToArray[2];
          }
        }
        secondaryBtn.url = this.slides[i].secondaryCtaBtn;
        secondaryBtn.label = this.slides[i].secondaryCtaBtnLabel;
        secondaryBtn.themeClass = this.getCtaButtonClass(this.slides[i].secondaryCtaBtnTheme);
        secondaryBtn.ariaLabel = this.slides[i].secondaryCtaBtnAriaLabel;
        secondaryBtn.target = '_self';
        if (this.slides[i].secondaryCtaBtnOpenInANewWindow) {
          secondaryBtn.target = '_blank';
        }
        content.secondaryBtn = secondaryBtn;
        // Positioning Content

        // contentPositionDesktop
        // contentPositionMobile

        // Default Positioning Values For Text Box Text
        content.desktopTextBoxWidth = '50%';
        content.desktopFlexButtonAlign = 'flex-start';
        content.mobileFlexButtonAlign = 'flex-start';
        content.desktopTextAlign = 'left';
        content.mobileTextAlign = 'left';
        content.imageDisplayClassDesktop = 'flex';
        content.imageDisplayClassMobile = 'flex';

        // Desktop Content Positions
        switch (this.slides[i].contentPositionDesktop) {
          case 'left':
            content.desktopTextOrder = 1;
            content.desktopImageOrder = 2;
            break;
          case 'right':
            content.desktopTextOrder = 2;
            content.desktopImageOrder = 1;
            break;
          case 'center':
            content.desktopTextOrder = 2;
            content.desktopImageOrder = 1;
            content.desktopFlexButtonAlign = 'center';
            content.desktopTextBoxWidth = '75%';
            content.desktopTextAlign = 'center';
            content.imageDisplayClassDesktop = 'none';

            break;
          default:
            content.desktopTextOrder = 1;
            content.desktopImageOrder = 2;
        }

        // Mobile Content Positions
        switch (this.slides[i].contentPositionMobile) {
          case 'top':
            content.mobileTextOrder = 1;
            content.mobileImageOrder = 2;
            content.mobileFlexButtonAlign = 'center';
            content.mobileTextAlign = 'center';

            break;
          case 'center':
            content.mobileFlexButtonAlign = 'center';
            content.mobileTextAlign = 'center';
            content.imageDisplayClassMobile = 'none';

            break;
          case 'bottom':
            content.mobileTextOrder = 2;
            content.mobileImageOrder = 1;

            break;
          default:
            // Empty
            content.mobileTextOrder = 1;
            content.mobileImageOrder = 2;
        }

        // Stack image and text
        if (this.slides[i].stackTextImageMobile) {
          this.stackTextImageMobile = 'stack-items';
        }

        if (this.slides[i].contentPositionDesktop === 'center') {
          // content.imageUrl = null;
        }

        // if centered
        // content.imageUrl = null;

        slide.content = content;
        // Overall Theming
        slide.theme = this.slides[i].colorTheme;
        slide.overlayOpacity = this.slides[i].backgroundOverlayOpacity;
        // Background Imagery
        if (this.slides[i].backgroundImageDesktop[0] && this.bannerSize.desktopBannerAspectRatio === 'default') {
          slide.desktopBgImageUrl = this.slides[i].backgroundImageDesktop[0].lpBackgroundWide.src;
        } else {
          slide.desktopBgImageUrl = null;
        }
        if (this.slides[i].backgroundVideo[0]) {
          slide.desktopBgVideoUrl = this.slides[i].backgroundVideo[0].url;
        } else {
          slide.desktopBgVideoUrl = null;
        }
        if (this.slides[i].backgroundImageMobile[0] && this.bannerSize.mobileBannerAspectRatio === 'default') {
          slide.mobileBgImageUrl = this.slides[i].backgroundImageMobile[0].lpImageTextUncropped.src;
          slide.mobileBgImageSrcset = this.slides[i].backgroundImageMobile[0].lpImageTextUncropped;
        } else {
          slide.mobileBgImageUrl = slide.mobileBgImageSrcset = null;
        }

        // Background Image Aspect Ratio (desktop)
        if (this.slides[i].backgroundImageDesktop[0] && this.bannerSize.desktopBannerAspectRatio !== 'default') {
          const image = this.slides[i].backgroundImageDesktop[0];
          switch (this.bannerSize.desktopBannerAspectRatio) {
            case '1.85,1':
              if (image.landingPage1851) {
                slide.desktopBgImageUrl = image.landingPage1851.src;
              }
              break;
            case '16,9':
              if (image.landingPage169) {
                slide.desktopBgImageUrl = image.landingPage169.src;
              }
              break;
            case '1,1':
              if (image.landingPage11) {
                slide.desktopBgImageUrl = image.landingPage11.src;
              }
              break;
            case '2.35,1':
              if (image.landingPage2351) {
                slide.desktopBgImageUrl = image.landingPage2351.src;
              }
              break;
            case '5,3':
              if (image.landingPage53) {
                slide.desktopBgImageUrl = image.landingPage53.src;
              }
              break;
            case '7,5':
              if (image.landingPage75) {
                slide.desktopBgImageUrl = image.landingPage75.src;
              }
              break;
          }
        }

        // Background Image Aspect Ratio (mobile)
        if (this.slides[i].backgroundImageMobile[0] && this.bannerSize.mobileBannerAspectRatio !== 'default') {
          const mobileImage = this.slides[i].backgroundImageMobile[0];
          switch (this.bannerSize.mobileBannerAspectRatio) {
            case '1.85,1':
              if (mobileImage.landingPage1851) {
                slide.mobileBgImageUrl = mobileImage.landingPage1851.src;
                slide.mobileBgImageSrcset = mobileImage.landingPage1851;
              }
              break;
            case '1,1.85':
              if (mobileImage.landingPageMobile1185) {
                slide.mobileBgImageUrl = mobileImage.landingPageMobile1185.src;
                slide.mobileBgImageSrcset = mobileImage.landingPageMobile1185;
              }
              break;
            case '16,9':
              if (mobileImage.landingPage169) {
                slide.mobileBgImageUrl = mobileImage.landingPage169.src;
                slide.mobileBgImageSrcset = mobileImage.landingPage169;
              }
              break;
            case '9,16':
              if (mobileImage.landingPageMobile916) {
                slide.mobileBgImageUrl = mobileImage.landingPageMobile916.src;
                slide.mobileBgImageSrcset = mobileImage.landingPageMobile916;
              }
              break;
            case '1,1':
              if (mobileImage.landingPage11) {
                slide.mobileBgImageUrl = mobileImage.landingPage11.src;
                slide.mobileBgImageSrcset = mobileImage.landingPage11;
              }
              break;
            case '2.35,1':
              if (mobileImage.landingPage2351) {
                slide.mobileBgImageUrl = mobileImage.landingPage2351.src;
                slide.mobileBgImageSrcset = mobileImage.landingPage2351;
              }
              break;
            case '1,2.35':
              if (mobileImage.landingPageMobile1235) {
                slide.mobileBgImageUrl = mobileImage.landingPageMobile1235.src;
                slide.mobileBgImageSrcset = mobileImage.landingPageMobile1235;
              }
              break;
            case '5,3':
              if (mobileImage.landingPage53) {
                slide.mobileBgImageUrl = mobileImage.landingPage53.src;
                slide.mobileBgImageSrcset = mobileImage.landingPage53;
              }
              break;
            case '3,5':
              if (mobileImage.landingPageMobile35) {
                slide.mobileBgImageUrl = mobileImage.landingPageMobile35.src;
                slide.mobileBgImageSrcset = mobileImage.landingPageMobile35;
              }
              break;
            case '7,5':
              if (mobileImage.landingPage75) {
                slide.mobileBgImageUrl = mobileImage.landingPage75.src;
                slide.mobileBgImageSrcset = mobileImage.landingPage75;
              }
              break;
            case '5,7':
              if (mobileImage.landingPageMobile57) {
                slide.mobileBgImageUrl = mobileImage.landingPageMobile57.src;
                slide.mobileBgImageSrcset = mobileImage.landingPageMobile57;
              }
              break;
          }
        }
        slideArray.push(slide);
      }
      /*
      // Debugging Code
      console.log('==========================');
      console.log('Object:');
      console.log(slideArray);
      console.log('==========================');
      */
      this.slideData = slideArray;
      this.isVideoPlaying = slideArray.reduce((acc, _, key) => {
        acc[key] = true;
        return acc;
      }, {});
    },
    setTheme() {
      // Set's Colors within the slide.
      if (this.slideData.length > 0) {
        this.slideThemeClass = this.slideData[this.currentSlideId].theme;
        if (this.slideData[this.currentSlideId].content.boxEnabled) {
          // Show Bounding Box Around Text
          this.slideContentBoxThemeClass = this.slideData[this.currentSlideId].theme;
        } else if (this.slideThemeClass === 'black' || this.slideThemeClass === 'darkGray') {
          // If box is disabled and background is black or darkGray, make the box transparent and set text to white
          this.slideContentBoxThemeClass = 'transparent-box-white-text';
        } else {
          // If box is disabled and background is gray, yellow, or white, make the box transparent and set text to black
          this.slideContentBoxThemeClass = 'transparent-box';
        }
        if (this.slideThemeClass === 'black' || this.slideThemeClass === 'darkGray') {
          this.textIndicatorThemeClass = 'light';
          this.indicatorIconColor = 'white';
        } else {
          this.textIndicatorThemeClass = 'dark';
          this.indicatorIconColor = 'black';
        }
        if (this.slideData[this.currentSlideId].content.textShadowsEnabled) {
          this.textShadowThemeClass = 'text-shadow';
        } else {
          this.textShadowThemeClass = '';
        }
      }
    },
    goToPreviousSlide() {
      // Go Back a Slide
      if (this.currentSlideId === 0) {
        this.currentSlideId = this.slideData.length - 1;
      } else {
        this.currentSlideId = this.currentSlideId - 1;
      }
      this.setTheme();
    },
    goToNextSlide() {
      // Go to Next Slide
      if (this.currentSlideId === this.slideData.length - 1) {
        this.currentSlideId = 0;
      } else {
        this.currentSlideId = this.currentSlideId + 1;
      }
      this.setTheme();
    },
    goToSlide(slideId) {
      // Go to Specific Slide
      this.currentSlideId = slideId;
      this.setTheme();
    },
    pauseAutoplay() {
      // Pause the Autoplay
      if (this.checkIfAutoplayEnabled()) {
        clearTimeout(this.slideTimer);
        this.indicatorAnimatedClass = 'non-animated';
      }
    },
    executeAutoplay() {
      // Execute Play
      const vue = this;
      this.slideTimer = setTimeout(function () {
        vue.goToNextSlide();
        vue.executeAutoplay();
      }, this.duration);
    },
    setAutoplay() {
      // Check if Autoplay is Enabled

      if (this.checkIfAutoplayEnabled()) {
        const vue = this;
        this.indicatorAnimatedClass = 'animated';
        this.slideTimer = setTimeout(function () {
          vue.goToNextSlide();
          vue.executeAutoplay();
        }, this.duration);
      } else {
        this.indicatorAnimatedClass = '';
      }
    },
    checkIfAutoplayEnabled() {
      let autoplayEnabled = false;
      if (this.autoplay) {
        // Check if More than 1 Slide
        if (this.slideData.length > 0) {
          autoplayEnabled = true;
        }
      }
      return autoplayEnabled;
    },
    toggleVideoState(key) {
      const videoElement = this.$refs[`slideVideo${key}`]?.[0];
      if (videoElement) {
        if (this.isVideoPlaying[key]) {
          videoElement.pause();
          this.isVideoPlaying[key] = false;
        } else {
          videoElement.play();
          this.isVideoPlaying[key] = true;
        }
      }
    },
  },
  created() {},
  mounted() {
    this.init();
  },
  computed: {
    pauseResumeIcon() {
      return Object.keys(this.isVideoPlaying).reduce((acc, key) => {
        acc[key] = this.isVideoPlaying[key] ? 'pause_white' : 'play_white';
        return acc;
      }, {});
    },
    // watch: {
    //  pauseResumeIcon() {
    //   if
    //  }
    // },
  },
};
</script>

<style lang="scss">
@mixin absolute_fill() {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
@mixin transition($transition) {
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -o-transition: $transition;
  -ms-transition: $transition;
  transition: $transition;
}
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }
  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.pause-resume {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 3;
}
@media only screen and (max-width: 1020px) {
  .pause-resume {
    display: none;
    opacity: 0;
  }
}
.c_hero_banner {
  $self: &;
  position: relative;
  @at-root #{$self}__overlay {
    background: rgba($color_black_raw, 0.5);

    @at-root #{&}__video {
      width: 90vw;
      max-width: 100%;
      overflow: hidden;
      @include mq($mq_sm_to_md) {
        width: 70vw;
      }
      @include mq($mq_md_to_lg) {
        width: px_to_rem(800);
      }
    }
  }

  position: relative;
  width: 100%;
  overflow-y: hidden;
  /* Theme Backgrounds */
  .white {
    background-color: white;
  }
  .black {
    background-color: black;
    color: #ffffff;
  }
  .transparent-box {
    background-color: transparent;
  }
  .transparent-box-white-text {
    background-color: transparent;
    color: #ffffff;
  }
  .yellow {
    background-color: #ffb700;
  }
  .gray {
    background-color: rgb(204, 204, 204);
  }
  .darkGray {
    background-color: rgb(41, 41, 41);
  }

  @at-root #{$self}__slide {
    position: relative;
    overflow-x: hidden;
    .sizing-box {
      display: grid;
      @include transition(all 0.25s ease);
    }
    // Stack items (mobile only)
    @media only screen and (max-width: 768px) {
      .sizing-box.stack-items {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }

  @include mq($mq_md_to_lg) {
    /* Desktop Only Classes*/
    .sizing-box.desktop-set-height {
      height: var(--desktop-banner-height);
    }
    // Opportunity to reduce code
    .desktop_aspect_16x9 {
      @include aspect-ratio(16, 9);
    }
    .desktop_aspect_1_85x1 {
      @include aspect-ratio(1.85, 1);
    }
    .desktop_aspect_2_35x1 {
      @include aspect-ratio(2.35, 1);
    }
    .desktop_aspect_5x3 {
      @include aspect-ratio(5, 3);
    }
    .desktop_aspect_7x5 {
      @include aspect-ratio(7, 5);
    }
    .desktop_aspect_1x1 {
      @include aspect-ratio(1, 1);
    }
  }
  @media only screen and (max-width: 1024px) {
    /* Mobile Only Classes */
    @at-root #{$self}__slide {
      .sizing-box.mobile-set-height {
        height: var(--mobile-banner-height);
      }
    }
    // Opportunity to reduce code
    .mobile_aspect_16x9 {
      @include aspect-ratio(16, 9);
    }
    .mobile_aspect_9x16 {
      @include aspect-ratio(9, 16);
    }
    .mobile_aspect_1_85x1 {
      @include aspect-ratio(1.85, 1);
    }
    .mobile_aspect_1x1_85 {
      @include aspect-ratio(1, 1.85);
    }
    .mobile_aspect_2_35x1 {
      @include aspect-ratio(2.35, 1);
    }
    .mobile_aspect_1x2_35 {
      @include aspect-ratio(1, 2.35);
    }
    .mobile_aspect_5x3 {
      @include aspect-ratio(5, 3);
    }
    .mobile_aspect_3x5 {
      @include aspect-ratio(3, 5);
    }
    .mobile_aspect_7x5 {
      @include aspect-ratio(7, 5);
    }
    .mobile_aspect_5x7 {
      @include aspect-ratio(5, 7);
    }
    .mobile_aspect_1x1 {
      @include aspect-ratio(1, 1);
    }
  }
  .desktop {
    // Hide for Mobile
    display: none;
    @include mq($mq_md_to_lg) {
      // Show for Desktop
      display: flex;
    }
  }
  .mobile {
    // Show for Mobile
    @include mq($mq_md_to_lg) {
      // Hide for Mobile
      display: none;
    }
  }
  /* Background Overlay */
  /* Video BG: https://www.w3schools.com/howto/howto_css_fullscreen_video.asp */
  .c_hero_banner__slide {
    display: none;
  }
  .c_hero_banner__slide.active {
    display: flex;
  }
  @at-root #{$self}__background {
    $background: &;
    @include absolute_fill();
    // Stack items (mobile only)
    @at-root #{$self}__background.stack-items {
      @media only screen and (max-width: 768px) {
        position: relative;
      }
    }
    @at-root #{$background} video {
      object-fit: cover;
    }
    @at-root #{$background}__overlay {
      @include absolute_fill();
      background-color: black;
      opacity: var(--opacity);
      z-index: 2;
    }
    @at-root #{$background}__overlay.light {
      background-color: white;
    }
    @at-root #{$background}__visual {
      $visual: &;
      @include absolute_fill();
      background-color: transparent;
      z-index: 1;
      background-size: cover;
      background-position: center;
      overflow-x: hidden;
      overflow-y: hidden;
      /*c_hero_banner__background__visual__video{*/
      @at-root #{$visual}__video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        // width: auto;
        // height: auto;
        z-index: -100;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        background-size: cover;
        // overflow-x: hidden;
      }
    }
  }
  @at-root #{$self}__content {
    $content: &;
    position: absolute;
    top: 0px;
    width: 100%;
    bottom: 50px;
    display: flex;
    left: 0px;
    z-index: 3;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
    /*.c_wrapper {*/
    .content_wrapper {
      display: flex;
      overflow-y: hidden;
      justify-content: center;
      align-items: stretch; /* might need to modify*/
      align-content: stretch;
      flex-direction: row;
      grid-template-areas:
        'content-area'
        'image-area';
      margin-left: auto;
      margin-right: auto;
      width: var(--max-width); /*1024px;*/ /* Grid Width*/
      height: 100%;
      .content-container-box {
        /*justify-content:center; text-align: center;*/
        grid-area: content-area;
        display: flex;
        width: var(--desktop-text-box-width);
        order: var(--desktop-text-order);
        text-align: var(--desktop-text-align);
        justify-content: center;
        align-items: center;
        align-content: center;
        .content-box {
          display: flex;
          padding: 25px; /* Make Editable */
          margin: 0px; /* Make Editable */
          flex-direction: column;
          .cta_container {
            display: flex;
            flex-direction: row;
            justify-content: var(--desktop-justify-align);
            button,
            a {
              margin-right: 15px;
              margin-bottom: 0px;
              width: auto;
              border-width: 2px;
              @include transition(all 0.25s ease);
            }
            a:last-child {
              margin-right: 0px;
            }
            .primary-btn {
              background-color: #000000;
              border-color: #000000;
            }
            .primary-btn:hover {
              background-color: #ffffff;
              color: #000000;
              cursor: pointer;
            }
            .secondary-btn {
              background-color: transparent;
              color: #000;
              border: #000000 2px solid;
            }
            .secondary-btn:hover {
              background-color: #000000;
              color: #ffffff;
              border-color: #000000;
              cursor: pointer;
            }
            a.defaultTheme {
              background-color: var(--color-red);
              color: #fff;
              border: var(--color-red) 2px solid;
              margin-right: 15px;
            }
            a.defaultTheme:hover {
              background-color: #000;
              color: #fff;
              border: #000 2px solid;
            }
            button.defaultTheme {
              background-color: var(--color-red);
              color: #fff;
              border: var(--color-red) 2px solid;
            }
            button.defaultTheme:hover {
              background-color: #000;
              color: #fff;
              border: #000 2px solid;
            }
          }
        }
        .content-box.light {
          color: #ffffff;
          .cta_container {
            .primary-btn {
              background-color: #ffffff;
              color: #000000;
              border-color: #ffffff;
            }
            .primary-btn:hover {
              background-color: black;
              color: #ffffff;
              border-color: #ffffff;
            }
            .secondary-btn {
              background-color: transparent;
              color: #ffffff;
              border-color: #ffffff;
            }
            .secondary-btn:hover {
              background-color: #ffffff;
              color: #000000;
              border-color: #ffffff;
            }
            a.defaultTheme {
              background-color: var(--color-red);
              color: #fff;
              border: var(--color-red) 2px solid;
            }
            a.defaultTheme:hover {
              background-color: #fff;
              color: #000;
              text-shadow: none;
              border: #fff 2px solid;
            }
            button.defaultTheme {
              background-color: var(--color-red);
              color: #fff;
              border: var(--color-red) 2px solid;
            }
            button.defaultTheme:hover {
              background-color: #000;
              color: #fff;
              border: #000 2px solid;
            }
          }
        }
        .content-box.text-shadow {
          text-shadow: var(--shadow-offset) var(--shadow-offset) var(--shadow-blur) #000000;
          h1 {
            text-shadow: var(--shadow-offset) var(--shadow-offset) var(--shadow-blur) #000000;
          }
          .cta_container {
            .primary-btn {
              text-shadow: 0px 0px 0px #000000;
            }
            .primary-btn:hover {
              text-shadow: 1px 1px 1px #000000;
            }
            .secondary-btn {
              text-shadow: 1px 1px 1px #000000;
            }
            .secondary-btn:hover {
              text-shadow: 0px 0px 0px #000000;
            }
          }
        }
      }
      .image-box {
        order: var(--desktop-image-order);
        grid-area: image-area;
        display: var(--desktop-image-display-class);
        position: relative;
        width: 50%;
        margin: 25px; /* Make Editable */
        align-items: stretch;
        align-content: stretch;
        align-self: center;
        height: var(--desktop-image-height);
        .image-box-image {
          @include absolute_fill();
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
  @media only screen and (min-width: 1024px) and (max-width: 1180px) {
    @at-root #{$self}__content {
      .content_wrapper {
        width: auto;
        max-width: 100%;
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    /*mq_md_to_lg*/
    @at-root #{$self}__content {
      bottom: 0px;
      flex-direction: row;
      /*.c_wrapper {*/
      .content_wrapper {
        display: flex;
        justify-content: center;
        align-items: center; /* might need to modify*/
        align-content: center;
        flex-direction: column;
        .content-container-box {
          display: flex;
          max-width: 100%;
          width: auto;
          text-align: var(--mobile-text-align);
          justify-content: center;
          align-items: center;
          align-content: center;
          flex-direction: row;
          order: var(--mobile-text-order);
          .content-box {
            padding: 25px; /* Make Editable */
            /* Margins are not added when the page does not include other widgets */
            .c_lp_text__eyebrow {
              margin-bottom: var(--eyebrow-mb, 1.2rem);
            }
            .c_lp_text__subheader {
              margin-bottom: var(--subheader-mb, 0.85rem);
            }
            .c_lp_text__text {
              margin-bottom: var(--text-mb, 1.5rem);
            }
            .cta_container {
              justify-content: var(--mobile-justify-align);
            }
          }
        }
        .image-box {
          display: var(--mobile-image-display-class);
          order: var(--mobile-image-order);
          width: 100%;
          margin: 0px; /* Make Editable */
          /* .image-box-image {
            item
          } */
        }
      }
    }
  }
  // Stack items (mobile only)
  @media only screen and (max-width: 768px) {
    @at-root #{$self}__content.stack-items {
      position: relative;
      .content-container-box {
        width: 100%;
        .content-box {
          width: 100%;
        }
      }
    }
  }
  @at-root #{$self}__indicator {
    $indicator: &;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    z-index: 4;
    @at-root #{$self}__indicator__menu__container {
      position: absolute;
      bottom: 0px;
      height: 50px;
      width: 100%;
      /*@at-root #{$indicator}__menu {*/
      .c_hero_banner__indicator__menu {
        display: flex;
        justify-content: center;
        .indicator_btn {
          height: 50px;
          margin-right: 5px;
          .indicator {
            @include transition(all 0.25s ease);
            background-color: #000000;
            opacity: 0.5;
            height: 5px;
            width: 40px;
          }
          .indicator_label {
            display: none;
          }
          .indicator_icon {
            display: none;
          }
        }
        .indicator_btn:last-child {
          margin-right: 0px;
        }
        .indicator_btn:hover {
          .indicator {
            opacity: 1;
          }
        }
        .indicator_btn.active {
          .indicator {
            opacity: 1;
          }
        }
      }
      .c_hero_banner__indicator__menu.light {
        /* Note: Dark is default*/
        .indicator_btn {
          .indicator {
            background-color: #ffffff;
          }
        }
      }
    }
    @at-root #{$self}__indicator__menu__container.circle_indicator {
      .c_hero_banner__indicator__menu {
        display: flex;
        justify-content: center;
        .indicator_btn {
          margin-right: 10px;
          .indicator {
            width: 10px;
            height: 10px;
            border-radius: 20px;
          }
        }
      }
    }
    @at-root #{$self}__indicator__menu__container.icon_indicator {
      /*Statically Set to Default Indicators*/
      background-color: #000000;
      .c_hero_banner__indicator__menu {
        display: flex;
        justify-content: center;
        .indicator_btn {
          .indicator {
            background-color: #ffffff;
          }
        }
      }
    }
    @include mq($mq_md_to_lg) {
      /* The Icon Nav is only on Desktop Displays */
      @at-root #{$self}__indicator__menu__container.icon_indicator {
        /* Indicator Menu has icons*/
        background-color: #000000;
        .c_hero_banner__indicator__menu {
          display: flex;
          justify-content: space-between;
          align-content: stretch;
          align-items: stretch;
          position: relative;
          .indicator_btn {
            width: 100%;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: flex-start;
            .indicator {
              position: absolute;
              display: inline-block;
              top: 0px;
              height: 3px;
              background-color: var(--color-red);
              width: 100%;
              opacity: 0;
            }
            .indicator_label {
              display: inline-flex;
              padding-left: 5px;
              padding-right: 5px;
              font-size: 14px;
              color: #ffffff;
            }
            .indicator_icon {
              display: inline-flex;
              margin-right: 10px;
              margin-left: 10px;
              width: 30px;
              height: 30px;
            }
          }
          .indicator_btn:hover {
            .indicator {
              background-color: white;
              opacity: 1;
            }
          }
          .indicator_btn.active {
            .indicator {
              opacity: 1;
            }
            .indicator.animated {
              width: 0px;
              animation-name: indicator-animation;
              animation-duration: var(--indicator-timer);
              animation-iteration-count: infinite;
              animation-timing-function: linear;
              animation-iteration-count: 1;
            }
            .indicator.non-animated {
              width: 100%;
              animation-name: indicator-non-animation;
              animation-duration: 300ms;
              animation-timing-function: ease-in;
              animation-iteration-count: 1;
            }
            @keyframes indicator-non-animation {
              100% {
                width: 100%;
              }
            }
            @keyframes indicator-animation {
              0% {
                width: 0px;
              }
              100% {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  @at-root #{$self}__navigation {
    $navigation: &;
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    height: 100%;
    .arrow-nav {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding-left: 25px;
      padding-right: 25px;
      .arrow-nav-btn {
        position: relative;
        @include transition(all 0.1s ease);
        display: flex;
        border: black 0px solid;
        border-radius: 50px;
        height: 50px;
        width: 50px;
        justify-content: center;
        align-items: center;
        z-index: 9;
        margin-bottom: 50px;
        .c_icon_svg {
          @include transition(all 0.25s ease);
          margin-left: 0px;
          margin-right: 0px;
          z-index: 9;
        }
        .icon_bg {
          @include transition(all 0.5s ease);
          position: absolute;
          opacity: 0;
          top: 0px;
          left: 0px;
          width: 100%;
          height: 100%;
          background-color: #fff;
          border-radius: 50px;
          z-index: 3;
        }
      }
      .arrow-nav-btn:hover {
        /*border-width: 2px;*/
        .icon_bg {
          opacity: 0.75;
        }
      }
      .left-btn:hover {
        .c_icon_svg {
          margin-left: -5px;
        }
      }
      .right-btn:hover {
        .c_icon_svg {
          margin-right: -5px;
        }
      }
    }
  }
  @at-root #{$self}__navigation.light {
    .arrow-nav {
      .arrow-nav-btn {
        .icon_bg {
          background-color: #000000;
        }
        /* .c_icon_svg {

        } */
      }
    }
  }
  @at-root #{$self}__navigation.dark {
    .arrow-nav {
      .arrow-nav-btn {
        .icon_bg {
          background-color: #ffffff;
        }
        /* .c_icon_svg {

        } */
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    @at-root #{$self}__navigation {
      display: none;
    }
  }
}
</style>
